import { Global, css } from '@emotion/react';

/**
 * Represents the global style component.
 * @returns The global style conponet.
 * @example
 * <GlobalStyle />
 */
const GlobalStyle = () => {
  return (
    <Global
      styles={theme => css`
        html,
        body,
        p,
        a,
        span,
        div,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: 'Assistant', sans-serif;
          margin-top: 0;
          margin-right: 0;
          margin-bottom: 0;
          margin-left: 0;
          padding-top: 0;
          padding-right: 0;
          padding-bottom: 0;
          padding-left: 0;
        }

        * {
          box-sizing: border-box;
        }

        #__next {
          background-color: #f4f5f6;
          height: 100%;
          margin: 0 auto;
        }

        body {
          background-color: #f4f5f6;
        }

        .ant-picker-panel-container {
          border-radius: 8px;
        }
        .ant-select-item.ant-select-item-option.ant-select-item-option-active {
         color: #0C7435;
         background-color: #D3EDDE;
        }
        .ant-select-item.ant-select-item-option:hover {
         color: #0C7435;
         background-color: #D3EDDE;
        }
        .ant-picker-focused {
          border-color: #075B3A;
        }
        .ant-picker-dropdown {
          font-size: 16px;
        }
        .ant-picker-dropdown .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner{
          color: #0C7435;
          background-color: #D3EDDE;
        }
        .ant-picker-dropdown .ant-picker-now-btn {
          color: #0C7435;
        }
        .ant-picker-dropdown .ant-btn {
          border-radius: 4px;
          background-color: #0C7435;
          border-color: #0C7435;
          color: #FFF;
          font-size: 16px;
          padding: 0 16px;
          height: auto;
        }
        .ant-picker-time-panel-column::after {
          height: auto;
        }
        .ant-picker-header-view button:hover {
          color: #0C7435;
        }
        .export .ant-popover-inner {
          border-radius: 8px;
        }
        .export .ant-popover-buttons {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
        }
        .export .ant-btn-primary {
          background-color: #0C7435;
          border-color: #0C7435;
          border-radius: 4px;
          font-size: 16px;
          padding: 4px 20px;
          height: auto;
        }
        .export .ant-btn-primary:hover {
          background-color: #075B3A;
          border: 1px solid #075B3A;
        }
        .export .ant-btn-primary:focus {
          outline: 3px solid #EDF8F1;
        }
        .export .ant-btn-default {
          color: #0C7435;
          border-color: #0C7435;
          border-radius: 4px;
          font-size: 16px;
          padding: 4px 20px;
          height: auto;
        }
        .export .ant-btn-default:hover {
          background-color: #075B3A;
          border: 1px solid #075B3A;
          color: #fff;
        }
        .export .ant-btn-default:active {
          color: #0C7435;
          border-color: #0C7435;
        }
        .export .ant-btn-default:focus {
          color: #0C7435;
          border-color: #0C7435;
        }
        .export .anticon svg {
          font-size: 16px;
          fill: #20f;
        }
        .export .ant-popover-message-title {
          font-size: 16px;
        }
        .ant-notification-notice-icon {
          top: 18px;
        }
        .ant-notification-notice-close-x {
          top: 5px;
          position: absolute;
          right: 6px;
        }
      `}
    />
  );
};

export default GlobalStyle;
