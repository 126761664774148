import { FC } from 'react';
import { AppProps } from 'next/app';
import { Provider as StoreProvider } from 'react-redux';
import ThemeProvider from '@/lib/provider';
import { store } from '@/lib/store';
import GlobalStyle from '@/lib/styles/GlobalStyle';

const App: FC<AppProps | any> = ({ Component, pageProps }) => {
  return (
    <StoreProvider store={store}>
        <ThemeProvider theme='cia'>
          <Component {...pageProps} />
          <GlobalStyle />
        </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
