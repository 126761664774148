import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Response from '@/lib/model/api/response';

const { API } = process.env;
const { ENV } = process.env;

export const api = createApi({
  reducerPath: 'api/search',
  baseQuery: fetchBaseQuery({ baseUrl: ENV !== 'production' ? '/api/' : API }),
  endpoints: builder => ({
    searchProgram: builder.mutation<Response, any>({
      query: ({ payload, name }) => ({
        method: 'GET',
        url: ENV !== 'production' ? 'programs' : 'v1/program?lang=pt',
        query: {
          ...payload,
        },
        params: {
          name,
        },
      }),
      transformResponse: ({ data }) => data,
    }),
  }),
});

export const { useSearchProgramMutation } = api;
