import 'antd/dist/antd';
import { ConfigProvider } from 'antd';
import { ThemeProvider } from '@emotion/react';
import antdCustomTheme from '@/lib/styles/customTheme';
import GlobalStyle from '@/lib/styles/GlobalStyle';
import theme from '@/lib/styles';

export default function AntdProvider({ children }: any) {

  const customTheme = theme({ brand: 'cia' });


  return (
    <ConfigProvider theme={antdCustomTheme} >
      <GlobalStyle />
      <ThemeProvider theme={customTheme}>
        {children}
      </ThemeProvider>
    </ConfigProvider>
  );
}
