import { createSlice } from '@reduxjs/toolkit';

export const configSlice = createSlice({
  name: 'config',
  initialState: {
    feedback: {
      errors: {},
      message: '',
      show: false,
      type: '',
    },
    notification: {
      description: '',
      duration: 0,
      message: '',
      show: false,
      type: '',
    },
    menuAction: '',
    openedKeys: [],
    selectedKey: [],
    authorization: '',
    recoveryPass: {
      expired: false,
    },
    resetPass: {
      authorization: '',
      timeout: 5000,
    },
    language: 'pt',
    languageFormat: 'pt',
    theme: 'cia',
    mock: {
      delay: 3000,
      method: 'GET',
      mockAll: false,
      path: '/api/',
      response: '{"status": "ok"}',
      responseHeader: [],
      statusCode: 200,
      useMocked: false,
      useResponseDefault: true,
    },
  },
  reducers: {
    setMenuAction: (state, { payload }) => ({
      ...state,
      menuAction: payload,
    }),
    setKeys: (state, { payload }) => (
      {
      ...state,
      ...payload,
    }),
    setAuthorization: (state, { payload }) => ({
      ...state,
      payload,
    }),
    setStep: (state, { payload }) => ({
      ...state,
      step: payload,
    }),
    setTheme: (state, { payload }) => ({
      ...state,
      theme: payload,
    }),
    setMock: (state, { payload }) => ({
      ...state,
      mock: payload,
    }),
    setLanguageFormat: (state, { payload }) => ({
      ...state,
      languageFormat: payload,
      language: payload,
    }),
    setFeedback: (state, { payload }) => ({
      ...state,
      feedback: payload,
    }),
    setNotification: (state, { payload }) => ({
      ...state,
      notification: payload,
    }),
  },
});

export const {
  setAuthorization,
  setFeedback,
  setKeys,
  setLanguageFormat,
  setMenuAction,
  setMock,
  setNotification,
  setStep,
  setTheme,
} = configSlice.actions;

export default configSlice.reducer;
