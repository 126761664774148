import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api as searchApi } from '@/lib/service/search';
import config from './config';
import errors from './errors';


export const store = configureStore({
  reducer: {
    config,
    errors,
    [searchApi.reducerPath]: searchApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
      },
    }).concat(searchApi.middleware),
});
setupListeners(store.dispatch);
