import { ThemeConfig } from 'antd';

const theme: ThemeConfig = {
  token: {
    colorPrimary: '#075b3a',
    colorLink: '#075b3a',
    fontFamily: 'Assistant, sans-serif',
  },
  components: {
    Form: {
      verticalLabelPadding: 0,
    },
    Table: {
      rowHoverBg: '#edf8f1',
    },
  },
};

export default theme;
